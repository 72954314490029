.container {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
}

.metricsContainer {
    width: 90%;
}

.main {
    margin-top: 40px;
}

.registrations {
    margin-top: 40px;
}

.stores {
    margin-top: 40px;
}

.orders {
    margin-top: 40px;
}