.cardContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 100%;
    gap: 10px;
}

.card {
    display: flex;
    flex-direction: column;
    background-color: white;
    gap: 12px;
    padding: 10px;
    border-radius: 15px;
    min-width: 22%;
    width: 250px;
    transition: 300ms;
}

.cardHeader {
    padding: 8px 20px;
    background: #ff7ea5;
    border-radius: 13px;
    display: flex;
    align-items: center;
}

.cardContainer span {
    margin-left: 10px;
}

.count {
    font-weight: 700;
}


.card:nth-child(1) .cardHeader {
    background: #ff7ea5;
}

.card:nth-child(2) .cardHeader {
    background-color: lightgrey;
}

.card:nth-child(3) .cardHeader {
    background-color: #c388f6;
}

.card:nth-child(4) .cardHeader {
    background-color: darkslateblue;
}

.card:hover {
    transform: scale(1.02);
    cursor: pointer;
}