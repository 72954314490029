@font-face {
    font-family: "Montserrat";
    src: local("Montserrat"), url("./assets/fonts/Montserrat-VariableFont_wght.ttf");
}

* {
    font-family: "Montserrat"
}

body {
    margin: 0px;
    background-color: #f6f7fb;
}

:root {
    --botique-blue: #3cadff;
    --boxberry-pink: #ed1651;
    --boxberry-green: #a6ce39;
}