.container {
    display: flex;
    margin: 200px;
    justify-content: center;
}

.authForm {
    display: flex;
    width: 50%;
    flex-direction: column;
    gap: 20px;
    text-align: center;
    padding: 20px 40px;
    border: 1px solid var(--botique-blue);
    border-radius: 16px;
}

span {
    cursor: pointer;
}

.authForm span:hover {
    color: var(--botique-blue);
}

.error input {
    border: 1px solid red;
}