.header {
    position: relative;
    width: 100%;
    height: 10vh;
}

.functional {
    height: 40px;
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    justify-content: end;
    background-color: var(--botique-blue);
}

hr {
    width: 100%;
    position: absolute;
    top: 30px;
    border: 0.2px solid black;
}

.functional span {
    padding: 8px 10px;
    margin-right: 100px;
    border-radius: 8px;
    transition: 300ms;
}

.functional span:hover {
    background-color: white;
    cursor: pointer;
}

.leftSide {
    position: absolute;
    top: 40px;
    left: 0;
    width: 60%;
    height: 100%;
    background-color: var(--botique-blue);
    z-index: 1;
    clip-path: polygon(0% 0%, 70% 0%, 100% 100%, 0% 100%);
    display: flex;
    align-items: center;
}

.rightSide {
    position: absolute;
    top: 40px;
    right: 0;
    width: 60%;
    height: 100%;
    background-color: var(--boxberry-green);
    z-index: 0;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 30% 100%);
    display: flex;
    align-items: center;
}

.leftSide img {
    margin-left: 20%;
    width: 40%;
}

.rightSide img {
    margin-left: 40%;
    width: 30%;
}