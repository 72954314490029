.chartContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 100%;
    gap: 10px;
    align-items: center;
}

.chartContainer .bar {
    height: 480px;
    width: 60%;
    min-width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chartContainer .pie {
    height: 400px;
    width: 30%;
    min-width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 800px) {
    .chartContainer .bar {
        height: 200px;
        width: 100%;
    }

    .chartContainer .pie {
        width: 100%;
    }
}