.ordersContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 100%;
    gap: 20px;
    align-items: center;
}

.cardContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    background-color: #5886ff;
}

.card {
    display: flex;
    flex-direction: column;
    color: white;
    gap: 12px;
    padding: 50px 10px;
    width: 250px;
    transition: 300ms;
}

.cardHeader {
    padding: 8px 20px;
    background: #ff7ea5;
    border-radius: 13px;
    display: flex;
    align-items: center;
}

.cardContainer span {
    margin-left: 10px;
}

.count {
    font-weight: 700;
}

.ordersContainer .sales {
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ordersContainer .categories {
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ordersContainer .distr {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10px;
}

.ordersContainer .distr div:first-child {
    width: 20%;
}

.ordersContainer .distr div:last-child {
    width: 70%;
}

@media (max-width: 800px) {
    .ordersContainer .distr {
        flex-direction: column;
    }

    .ordersContainer .distr div:first-child {
        width: 100%;
    }

    .ordersContainer .distr div:last-child {
        width: 100%;
    }
}